<template>
  <div>
    <oBanner></oBanner>
  </div>
</template>

<script>
import oBanner from "./components/banner/index.vue";
export default {
  components: {
    oBanner,
  },
};
</script>

<style lang="scss" scoped></style>
